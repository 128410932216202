import React, { useState } from "react";
import Layout from "../components/HakwareLayout";
import Hero from "../components/hero";
import ContactForm from "../components/HakwareForm";
import VulnerabilityTable from "../components/VulnerabilityTable";
import BuildNumberModal from "../components/BuildNumberModal";
import ScrollAnimation from "react-animate-on-scroll";
import LottiePlayerLight from "react-lottie-player/dist/LottiePlayerLight";
import "animate.css/animate.min.css";
import "../styles/hakware.scss";
import cyberRed2 from "../lotties/loading.json";
import Slider from "react-slick";

const Hakware = () => {
  const [responseData, setResponseData] = useState<any>(null);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const settings = {
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    dots: true,
  };

  return (
    <>
      <BuildNumberModal showModal={showModal} setShowModal={setShowModal} />
      <div id="hakware">
        <Layout>
          <section>
            <Hero
              title="Is Your VMware Environment Secure?"
              subtitle="Use our free vulnerability checker, powered by Hakware Archangel, and get emailed a detailed vulnerability report in seconds."
              id="home"
              scrollTo="#"
              button="Get Free Vulnerability Report"
            />
          </section>

          {responseData && (
            <VulnerabilityTable response={responseData} userInfo={userInfo} />
          )}

          <section className="refuse-ransom-img flex ac jc">
            <div className="wrapper grid col-2 w-max">
              <div className="lottie-div">
                <LottiePlayerLight animationData={cyberRed2} loop play />
              </div>
              <div className="refuse-ransom flex col as jc">
                <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
                  <h1 style={{ padding: "0", color: "black" }}>
                    Vanquish the vulnerability.
                  </h1>
                  <h3>
                    Discover the vulnerabilities in your VMware environment with
                    our free tool and act to ensure your organisation is
                    protected.
                  </h3>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="animate__fadeInUp"
                  duration={1}
                  delay={1}
                >
                  <div className="ransom-approach">
                    {" "}
                    <li>
                      ACT BEFORE YOUR ENEMIES DO
                      <p>
                        Hakware’s Arkangel scanner uses Artificial Intelligence
                        to help organisations assess systems quicker than
                        cybersecurity personnel. This gives you the competitive
                        edge, detecting threats quicker than it takes your
                        adversaries to act.
                      </p>
                    </li>
                    <li>
                      GET YOUR FREE REPORT IN UNDER 60 SECONDS
                      <p>
                        Enter your VMware product build number and let us do the
                        rest. We will send you a bite-sized vulnerability report
                        directly to your email within seconds.
                      </p>
                    </li>
                    <li>
                      MAKE YOUR PLATFORM BULLETPROOF
                      <p>
                        Use the insights from your free report to plan how
                        you’ll strengthen your security posture. Get in contact
                        with one of our cyber experts to schedule a detailed
                        audit and protect your organisation.
                      </p>
                    </li>
                  </div>
                </ScrollAnimation>
                <button
                  onClick={scrollToBottom}
                  style={{
                    border: "none",
                    cursor: "pointer",
                    padding: "20px",
                    backgroundColor: "#B91B20",
                    fontWeight: "bold",
                    fontSize: "var(--medium)",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                  className="shaker"
                >
                  GET FREE VULNERABILITY REPORT
                </button>
                <br />
                <br />
              </div>
            </div>
          </section>

          <section id="contact-section" style={{ minHeight: "100vh" }}>
            <div className="wrapper" id="contact-bkg">
              <div className="outer">
                <div className="grid col-2 ac jc">
                  <div className="left flex col">
                    <ScrollAnimation
                      animateIn="animate__fadeInDown"
                      duration={1}
                    >
                      <h2>Don’t wait until it's too late.</h2>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
                      <p>
                        Strategix Technology Solutions helps global
                        organisations protect and manage their business's
                        critical data, systems and infrastructure from cyber
                        threats.
                      </p>
                      <p>
                        Powered by Hakware Archangel, an industry-leading
                        Artificial, Intelligence-based vulnerability scanner and
                        pentesting tool, this free vulnerability checker
                        identifies vulnerabilities in your environment in
                        seconds.
                      </p>
                      <p>
                        Fill in the form and get emailed a detailed report
                        instantly.
                      </p>
                    </ScrollAnimation>
                  </div>
                  <div className="flex col ">
                    <ContactForm
                      openModal={openModal}
                      setResponseData={setResponseData}
                      setUserInfo={setUserInfo}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </div>
    </>
  );
};

export default Hakware;
