import React, { useEffect } from "react";
import "../styles/hakware.scss";

const BuildNumberModal = ({ showModal, setShowModal }: any) => {
  const disableScroll = () => {
    document.documentElement.classList.add("body-no-scroll");
  };

  const enableScroll = () => {
    document.documentElement.classList.remove("body-no-scroll");
  };

  useEffect(() => {
    if (showModal) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [showModal]);
  return (
    <>
      {showModal && (
        <div className="build-modal">
          <div
            className="quit-modal"
            onClick={() => {
              setShowModal(false);
            }}
          >
            X
          </div>
          <div className="modal-body">
            <h1>
              To determine the build number of ESX/ESXi using the vSphere
              Client:
            </h1>
            <ol>
              <li>
                Using the vSphere Client log in to the vCenter Server or
                ESX/ESXi host.
              </li>
              <li>
                In the Hosts and Clusters view, click the ESX/ESXi host in the
                inventory.
              </li>
              <li>
                Above the tabs, you see a line that identifies the host. This
                line includes the build number of the selected ESX/ESXi host.
              </li>
              <li>
                In 6.x and above use the <strong>http://host-name/ui</strong> or{" "}
                <strong>http://host-ip-address/ui</strong>.
              </li>
            </ol>
            <h1>
              Determining the build number of vCenter Server using the vSphere
              Client:
            </h1>
            <ol>
              <li>Log in to the vSphere Client.</li>
              <li>Select the vCenter Server.</li>
              <li>Click the Summary tab.</li>
              <li>
                The build is located under the Version Information section.
              </li>
            </ol>
          </div>
        </div>
      )}
    </>
  );
};

export default BuildNumberModal;
