import { Link, navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "../styles/HakwareForm.scss";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import animationData from "../lotties/loading2.json";
import Lottie from "react-lottie";

export default function ContactForm({
  setResponseData,
  setUserInfo,
  openModal,
}: any) {
  const [align, setAlign] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const RECAPTCHA_KEY: string = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY!;

  const [data, setData] = useState({
    firstName: "",
    email: "",
    phone: "",
    organisation: "",
    position: "",
    buildNumber: "",
  });

  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [latestBuildNumber, setLatestBuildNumber] = useState("");

  useEffect(() => {
    const fetchLatestBuildNumber = async () => {
      try {
        const response = await fetch(
          "/.netlify/functions/getLatestBuildNumber"
        );
        if (response.ok) {
          const { latestBuildNumber } = await response.json();
          setLatestBuildNumber(latestBuildNumber);
        } else {
          console.error("Failed to fetch latest build number");
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching the latest build number:",
          error
        );
      }
    };

    fetchLatestBuildNumber();
  }, []);

  async function submitToNetlifyFunction(data: any) {
    setIsSubmitting(true);
    try {
      const response = await fetch("/.netlify/functions/hakwareAPI", {
        method: "POST",
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const responseData = await response.json();
        setResponseData(responseData[0]);
        // console.log("API response:", responseData[0]);
      } else if (response.status === 404) {
        setError("No information found for Build Number");
        setIsSubmitting(false);
      } else {
        setIsSubmitting(false);
        throw new Error("API request failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setError("An error occurred while submitting the form.");
    } finally {
      // Keep isSubmitting set to true for an additional 20 seconds to keep button hidden until page load. Stupid hack
      setTimeout(() => {
        setIsSubmitting(false);
      }, 20000);
    }
  }

  function handle(e: any) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  }

  function isBusinessEmail(email: any) {
    const personalDomains = ["gmail.com", "hotmail.com", "yahoo.com"];

    const emailDomain = email.split("@")[1];

    return personalDomains.includes(emailDomain);
  }

  function isNumeric(value: any) {
    return /^\d+$/.test(value);
  }

  function isValidPhoneNumber(value: any) {
    // Regular expression to match a typical phone number format
    // You can adjust this regex as needed to match your specific phone number format requirements
    const phoneRegex = /^[0-9]{10}$/; // Assumes a 10-digit phone number

    return phoneRegex.test(value);
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { firstName, position, phone, buildNumber } = data;

    // Check if name and role fields contain only letters
    if (!/^[a-zA-Z\s]+$/.test(firstName) || !/^[a-zA-Z\s]+$/.test(position)) {
      alert("Name and role should only contain letters.");
      return;
    }

    // Check if phone and buildNumber fields contain only numbers
    if (!isNumeric(phone) || !isNumeric(buildNumber)) {
      alert("Phone number and build number should only contain numbers.");
      return;
    }

    // Check if phone number is a valid phone number
    if (!isValidPhoneNumber(phone)) {
      alert("Please enter a valid phone number.");
      return;
    }

    if (!isBusinessEmail(data.email)) {
      submitToNetlifyFunction(data);
    } else {
      alert("Please enter a business email address.");
    }
  }

  useEffect(() => {
    setUserInfo(data);
  }, [data]);

  return (
    <>
      <div className="hakware-form-wrapper">
        <div
          style={{
            alignItems: "left",
          }}
        >
          <div>
            <ScrollAnimation animateIn="animate__fadeInDown" duration={0.5}>
              <h2 style={{ textAlign: "left", paddingLeft: "10px" }}>
                Get your free vulnerability report
              </h2>
            </ScrollAnimation>

            <div>
              <style dangerouslySetInnerHTML={{ __html: "\n\n" }} />

              <div className="latest-build">
                {" "}
                {latestBuildNumber !== "" ? (
                  <strong>
                    Latest Available Data: Up To Build Number{" "}
                    {latestBuildNumber}
                  </strong>
                ) : (
                  <p>Loading Latest Build Number...</p>
                )}
              </div>

              <form
                name="hakware-form"
                method="post"
                data-netlify="true"
                className="form column"
                action="thank-you"
                onSubmit={handleSubmit}
                data-netlify-recaptcha="true"
              >
                {error && <div className="error-message">{error}</div>}
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="hakware-form" value="hakware-form" />
                <div className="row">
                  <div className="column">
                    <input
                      onChange={(e) => handle(e)}
                      value={data.firstName}
                      name="name"
                      id="firstName"
                      type="text"
                      tabIndex={1}
                      required
                      className="form-input"
                      placeholder="Name*"
                    />
                  </div>
                  <div className="column">
                    <input
                      onChange={(e) => handle(e)}
                      value={data.organisation}
                      name="organisation"
                      id="organisation"
                      type="text"
                      tabIndex={2}
                      required
                      className="form-input"
                      placeholder="Organisation*"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <input
                      onChange={(e) => handle(e)}
                      value={data.position}
                      name="position"
                      id="position"
                      type="text"
                      tabIndex={3}
                      required
                      className="form-input"
                      placeholder="Role*"
                    />
                  </div>
                  <div className="column">
                    <input
                      onChange={(e) => handle(e)}
                      value={data.email}
                      name="email"
                      id="email"
                      type="email"
                      tabIndex={4}
                      required
                      className="form-input"
                      placeholder="Email*"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <input
                      onChange={(e) => handle(e)}
                      value={data.phone}
                      name="phone"
                      id="phone"
                      type="tel"
                      tabIndex={5}
                      required
                      className="form-input"
                      placeholder="Phone Number*"
                      inputMode="numeric"
                    />
                  </div>
                  <div className="column">
                    <input
                      onChange={(e) => handle(e)}
                      value={data.buildNumber}
                      name="buildNumber"
                      id="buildNumber"
                      type="text"
                      tabIndex={6}
                      required
                      className="form-input"
                      placeholder="Build Number*"
                      inputMode="numeric"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <strong
                      style={{
                        fontSize: "14px",
                        color: "var(--red)",
                        cursor: "pointer",
                        width: "max-content",
                        paddingLeft: "5px",
                        paddingBlock: "var(--pad-2)",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        openModal();
                      }}
                      onMouseOver={(e) => {
                        e.currentTarget.style.color = "var(--blue";
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.color = "var(--red)";
                      }}
                    >
                      Find My Build Number
                    </strong>
                  </div>
                </div>
                <div className="row" style={{ paddingBottom: "24px" }}>
                  <div className="column">
                    <label
                      style={{
                        fontSize: "14px",
                        color: "#626366",
                        marginTop: "-28px",
                        paddingRight: "20px",
                        userSelect: "none",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="popi"
                        name="popi"
                        value="popi"
                        required
                        style={{
                          fontSize: "20px",
                          marginTop: "24px",
                          marginLeft: "5px",
                          minHeight: "0",
                        }}
                      />
                      <span style={{ fontWeight: "300" }}>
                        {" "}
                        &nbsp; I agree and consent to the{" "}
                        <Link to="/disclaimer" style={{ color: "#005b85" }}>
                          <strong>
                            Agreement for Data Gathering and Processing
                          </strong>
                        </Link>
                        .
                        {/* , specifically consenting to Strategix Technology
                        Solutions processing my data and communicating with me
                        according to that policy. */}
                      </span>
                    </label>
                  </div>
                </div>
                <div className="recaptcha" style={{ paddingLeft: "10px" }}>
                  <ReCAPTCHA sitekey={RECAPTCHA_KEY} />
                </div>
                <br />
                <div className="row">
                  <div className="column button">
                    <div className="button-container">
                      {!isSubmitting && (
                        <button
                          name="submit"
                          value="Submit"
                          type="submit"
                          id="contact-submit"
                          data-submit="...Sending"
                          disabled={isSubmitting}
                        >
                          Submit and get emailed report <span>&#x279C;</span>
                        </button>
                      )}
                      {isSubmitting && (
                        <div className="lottie-container flex jl ac">
                          <Lottie
                            options={{
                              loop: true,
                              autoplay: true,
                              animationData: animationData,
                            }}
                            height={100}
                            width={100}
                            style={{ margin: "0" }}
                          />
                          <p>Processing request...</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
