import React, { useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import hakware from "../images/transparent-logo.png";
import sts from "../images/strategix-logo.svg";
import "./VulnerabilityTable.scss";
import { navigate } from "gatsby";

interface Vulnerability {
  "CVE ID": string;
  Severity: string;
  Description: string;
}

interface Response {
  Status: string;
  BuildDetails: Array<any>;
  vulnerabilities: Array<Vulnerability>;
}

const VulnerabilityTable: React.FC<{ response: Response; userInfo: any }> = ({
  response,
  userInfo,
}) => {
  const tableRef = useRef<HTMLDivElement | null>(null);
  const { vulnerabilities, BuildDetails } = response;

  // Function to sort vulnerabilities by severity (Critical > High > Medium > Low)
  const sortVulnerabilitiesBySeverity = (vulnerabilities: Vulnerability[]) => {
    return vulnerabilities.sort((a, b) => {
      const severityOrder = {
        CRITICAL: 0,
        HIGH: 1,
        MEDIUM: 2,
        LOW: 3,
      };

      return severityOrder[a.Severity] - severityOrder[b.Severity];
    });
  };

  useEffect(() => {
    if (vulnerabilities) {
      const generatePDF = async () => {
        console.log("Generating PDF...");
        if (tableRef.current) {
          const rect = tableRef.current.getBoundingClientRect();
          const pdfHeightInMM = rect.height * 0.264583;
          const pdfWidthInMM = 210; // A4 width in mm

          const canvas = await html2canvas(tableRef.current, { scale: 2 });
          const imgData = canvas.toDataURL("image/jpeg", 0.5);

          const pdf = new jsPDF("p", "mm", [pdfWidthInMM, pdfHeightInMM]);
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

          const pdfBase64String = pdf.output("datauristring");

          fetch("/.netlify/functions/sendHakwareEmail", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              pdf: pdfBase64String.split(",")[1], // Remove the base64 MIME prefix
              name: userInfo?.firstName, // Replace with the actual name
              buildNumber: userInfo?.buildNumber, // Replace with the actual build number
              email: userInfo?.email,
            }),
          });
          navigate("/thank-you-hakware");
        }
      };

      setTimeout(() => {
        generatePDF();
      }, 1000);
    }
  }, [vulnerabilities]);

  return (
    <div className="vulnerability-table" ref={tableRef}>
      <div className="header flex ac">
        <div>
          <img src={sts} alt="sts-logo" />
        </div>
        <div>
          <img src={hakware} alt="hakware-logo" />
        </div>
      </div>
      <div className="divider"></div>
      <h1 style={{ textAlign: "left" }}>
        VMware {BuildDetails[0]?.Product} {BuildDetails[0]?.Version}{" "}
        Vulnerability Report
      </h1>
      <p>
        This vulnerability report is a collaborative effort between Strategix
        and Hakware to ensure the security and integrity of your VMware{" "}
        {BuildDetails[0]?.Product} {BuildDetails[0]?.Version} environment with
        build number {BuildDetails[0]["Build Number"]}.
        <br />
        <br />
        Cybersecurity threats are evolving rapidly, so it is crucial to identify
        and understand the vulnerabilities that could potentially compromise
        your system. The goal of this report is to provide you with a
        comprehensive list of identified vulnerabilities for your product, their
        severities, and detailed descriptions to assist you in making informed
        decisions for remediation.
        <br />
        <br />
        The following table lists all the vulnerabilities for VMware{" "}
        {BuildDetails[0]?.Product} {BuildDetails[0]?.Version} environment with
        build number {BuildDetails[0]["Build Number"]}.
      </p>
      <table border="1">
        <thead>
          <tr>
            <th>Vulnerability (CVE ID)</th>
            <th>Severity</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {sortVulnerabilitiesBySeverity(vulnerabilities)?.map(
            (vulnerability, index) => (
              <tr
                key={index}
                className={
                  vulnerability.Severity === "MEDIUM"
                    ? "medium-severity"
                    : vulnerability.Severity === "HIGH"
                    ? "high-severity"
                    : vulnerability.Severity === "CRITICAL"
                    ? "critical-severity"
                    : vulnerability.Severity === "LOW"
                    ? "low-severity"
                    : ""
                }
              >
                <td>{vulnerability["CVE ID"]}</td>
                <td>{vulnerability.Severity}</td>
                <td>{vulnerability.Description}</td>
              </tr>
            )
          )}
        </tbody>
      </table>

      <p>
        After reviewing the vulnerabilities listed in this report, it is highly
        recommended to take the following next steps:
      </p>
      <ol>
        <li>
          <strong style={{ backgroundColor: "rgb(255, 55, 55)" }}>
            Critical / High Severity:
          </strong>{" "}
          Immediate action required.
        </li>
        <li>
          <strong style={{ backgroundColor: "rgb(251, 251, 74)" }}>
            Medium Severity:
          </strong>{" "}
          Review and plan for necessary actions.
        </li>
        <li>
          <strong style={{ backgroundColor: "#09F90A" }}>Low Severity:</strong>{" "}
          Monitor and review at your earliest convenience.
        </li>
      </ol>
      <p>
        For a detailed consultation on how to mitigate these vulnerabilities and
        enhance the security posture of your VMware {BuildDetails[0]?.Product}{" "}
        {BuildDetails[0]?.Version} systems, contact our team of experts. We
        offer tailor-made solutions and support to help you navigate through
        these security challenges. Your security is our priority.
      </p>
      <p>
        For immediate assistance, please contact us at{" "}
        <a href="mailto:queries@strategix.co.za">queries@strategix.co.za</a>.
      </p>
    </div>
  );
};

export default VulnerabilityTable;
